 
<template>
  <div>
    <div class="container">
      <div class="head fx_ai">
        <van-tag size="medium" :color="infoData.votingStatus == '已结束' ? '#FBE7E6' : '#DAF5DC'" :text-color="infoData.votingStatus == '已结束' ? '#E35D58' : '#0DB918'">{{ infoData.votingStatus }}</van-tag>
        <div class="title van-ellipsis fx1">{{ infoData.name }}</div>
      </div>
      <div class="contentDesc">{{ infoData.greeting }}</div>
      <div class="info_time">
        <div class="time">发起时间：{{ infoData.createDate }}</div>
      </div>
      <div class="info_time">
        <div class="time">结束日期：{{ infoData.endDate }}</div>
      </div>
      <div class="info_time">
        <div class="number">共{{ infoData.paperCount }}位居民参与</div>
      </div>
    </div>
    <div class="" v-if="infoData.isSubmit==1">
      <van-cell-group :title="item.questionTitle" v-for="(item,index) in statisticsData" :key="index">
        <van-cell :title="val.optionText" :value="val.checkedCount+'票'" v-for="(val,inx) in item.options" :key="inx" />
      </van-cell-group>
    </div>
    <van-form @submit="onSubmit" v-else>
      <van-cell-group v-for="item in questionList" :key="item.id">
        <!-- 单选框 -->
        <van-field v-if="item.questionType === 0" :name="item.id" :label="item.questionTitle">
          <template #input>
            <van-radio-group v-model="formData[item.id]" direction="horizontal">
              <van-radio style="margin-bottom: 5px" v-for="item2 in item.options" :key="item2.id" :name="item2.id">{{ item2.optionText }}</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <!-- 多选 -->
        <van-field v-if="item.questionType === 1" :name="item.id" :label="item.questionTitle">
          <template #input>
            <van-checkbox-group v-model="formData[item.id]" direction="horizontal">
              <van-checkbox style="margin-bottom: 5px" v-for="item2 in item.options" :key="item2.id" :name="item2.id" shape="square">{{ item2.optionText }}</van-checkbox>
            </van-checkbox-group>
          </template>
        </van-field>
        <!-- 输入框 -->
        <van-field v-if="item.questionType === 2" v-model="formData[item.id]" :name="item.id" :label="item.questionTitle" :placeholder="item.questionTitle" />
      </van-cell-group>
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit" :loading="subLoading" :disabled="infoData.votingStatus == '已结束'">{{infoData.votingStatus == '已结束'?'已结束未投票':'提交'}}</van-button>
      </div>
    </van-form>
  </div>
</template>
 
<script>  
import { getVotingDetail, submit, statistics } from '@/api/votingApi'
export default {
  name: 'detail',
  data() {
    return {
      formData: {},
      infoData: {},
      statisticsData: [],
      imgList: [],
      questionList: [],
      questionnaireId: '',
      subLoading: false,
    }
  },
  created() {
    this.questionnaireId = this.$route.query.id
    this.getInfor()
  },
  mounted() { },
  methods: {
    onSubmit(val) {
      let arr = []
      for (const key in val) {
        if (Object.hasOwnProperty.call(val, key)) {
          const element = val[key]
          arr.push({
            questionId: key,
            result: Array.isArray(element) ? element.join(',') : element,
          })
        }
      }
      const optionsKeys = Object.keys(this.formData)
      if (optionsKeys.length === 0) {
        this.$toast({
          type: 'fail',
          message: '请选择选项后提交！',
          duration: 1000
        })
        return Promise.reject(new Error('选项不能为空！'))
      } else {
        const res = this.questionList.length === optionsKeys.length && this.questionList.filter(ele => !optionsKeys.includes(ele.id))
        if (!res) {
          this.$toast({
            type: 'fail',
            message: '请选择每个选项后提交！',
            duration: 1000
          })
          return Promise.reject(new Error('选择每个选项后提交！'))
        }
      }
      let openId = this.$store.getters.openId
      let data = {
        questionnaireId: this.questionnaireId,
        resultList: arr,
        openid: openId
      }
      this.subLoading = true
      submit(data).then(res => {
        this.subLoading = false
        if (res.code == 200) {
          let _this = this
          this.$toast({
            type: 'success',
            message: '提交成功',
            duration: 500,
            onClose: function () {
              _this.$router.go(-1)
            }
          })
          // this.$router.back(-1)
        } else {
          this.$toast.fail(res.msg)
        }
      }).catch(() => {
        this.subLoading = false
      })
    },
    getInfor() {
      this.$store.commit('SET_IS_LOADING', true)
      getVotingDetail({ questionnaireId: this.questionnaireId }).then(res => {
        this.questionList = res.data.questions
        this.infoData = res.data
        if(res.data.isSubmit==1){
          this.getCensus()
          return
        }
        this.$store.commit('SET_IS_LOADING', false)
      }).catch(() => {
        this.$store.commit('SET_IS_LOADING', false)
      })
    },
    getCensus() {
      statistics({ id: this.questionnaireId }).then(res => {
        this.statisticsData = res.data.questions
        this.$store.commit('SET_IS_LOADING', false)
      }).catch(() => {
        this.$store.commit('SET_IS_LOADING', false)
      })
    },
  }
}
</script>
 
<style lang='scss' scoped>
.container {
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  font-size: 14px;
  background: #fff;
}
.head {
  margin-top: 20px;

  .title {
    margin-left: 10px;
    font-size: 16px;
    font-weight: bold;
  }
}

.contentDesc {
  margin-top: 15px;
  min-height: 200px;
}

.images {
  flex-wrap: wrap;
  gap: 20px;
}

.info_time {
  margin-top: 10px;
  color: #999;

  .number {
    font-size: 12px;
  }
}

.layouter {
  padding: 16px 0;
}
</style>
